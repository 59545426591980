import React from 'react'
import RouterLink from './components/shared/RouterLink'

export default [
	{
		"features": [
			{
				"label": "Track Hours",
				"description": "Track time worked by clocking in and out and entering notes.",
				"paid": true,
				"free": true
			},
			{
				"label": "Timesheets",
				"description": "Add hours worked using a familiar week-by-week view.",
				"paid": true,
				"free": true
			},
			{
				"label": "Track Expenses",
				"description": "Track expenses with amounts and notes.",
				"paid": true,
				"free": true
			},
			{
				"label": "Dashboard",
				"description": "See graphs of time and expenses for projects at a glance.",
				"paid": true,
				"free": true
			},
			{
				"label": "Edit Your Time Logs and Expenses",
				"description": "Revise previously entered time and expenses or enter new ones after the fact.",
				"paid": true,
				"free": true
			},
			{
				"label": "Edit Time Logs and Expenses of Team Members",
				"description": "With appropriately granted permissions, update time and expense records for others.",
				"paid": true,
				"free": false
			}
		]
	},
	{
		"title": "Projects and Clients",
		"features": [
			{
				"label": "Use Projects to Categorize Time and Expenses",
				"description": "Assign time and expense records to projects.",
				"paid": true,
				"free": true
			},
			{
				"label": "Manage Clients",
				"description": "Keep a list of your clients. Track time and expenses for them.",
				"paid": true,
				"free": true
			},
			{
				"label": "Associate Projects with Clients",
				"description": "Projects can be assigned to clients.",
				"paid": true,
				"free": true
			},
			{
				"label": "Archive Projects",
				"description": "Projects can be archived and unarchived as needed.  Archived projects are hidden and do not count against the project limit on the Free Plan.",
				"paid": true,
				"free": true
			},
			{
				"label": "Project Limit",
				"description": "Make as many projects as you need with the Team Plan.",
				"paid": "No Limit",
				"free": "3"
			}
		]
	},
	{
		"title": "Reporting",
		"features": [
			{
				"label": "Run Reports",
				"description": "Choose report options to find matching time logs and expenses.",
				"paid": true,
				"free": true
			},
			{
				"label": "Use Rounding Modes",
				"description": "Select useful rounding modes for time logs such as six minute increments.",
				"paid": true,
				"free": true
			},
			{
				"label": "Use Date and Time Formats",
				"description": "Choose preferred date/time formats for displaying time values.",
				"paid": true,
				"free": true
			},
			{
				"label": "Time Zone and Currency Settings",
				"description": "Choose time zone and currency settings to match your locale.",
				"paid": true,
				"free": true
			},
			{
				"label": "Export to CSV",
				"description": "Export time logs and expenses to CSV files.",
				"paid": true,
				"free": true
			},
			{
				"label": "Export to Excel",
				"description": "Export time logs and expenses to Excel files.",
				"paid": true,
				"free": false
			}
		]
	},
	{
		"title": "Invoicing",
		"features": [
			{
				"label": "Create and Send Invoices",
				"description": "Create and send invoice PDFs via email to your clients.",
				"paid": true,
				"free": false
			},
			{
				"label": "Invoice History",
				"description": "Track the status of invoices over time.",
				"paid": true,
				"free": false
			},
			{
				"label": "Automatic Invoicing",
				"description": "Set up a schedule to have invoices automatically created.",
				"paid": true,
				"free": false
			},
			{
				"label": "Invoice PDFs",
				"description": "Preview or download invoice PDFs at any stage.",
				"paid": true,
				"free": false
			},
			{
				"label": "Add Time and Expenses to Invoices",
				"description": "Select projects and date ranges to add billable time and expenses to invoices with optional rounding modes.",
				"paid": true,
				"free": false
			},
			{
				"label": "Add Custom Line Items to Invoices",
				"description": "Add custom line items to invoices for cases such as flat-fee projects or added costs.",
				"paid": true,
				"free": false
			},
			{
				"label": "Excel Attachment for Time and Expenses",
				"description": "Attach an Excel spreadsheet to invoices with your time and expenses.",
				"paid": true,
				"free": false
			},
			{
				"label": "Export Invoices",
				"description": "Export invoice data to Excel or CSV, including formats suitable for import into QuickBooks or Xero.",
				"paid": true,
				"free": false
			}
		]
	},
	{
		"title": "Other Features",
		"features": [
			{
				"label": "Light and Dark Themes",
				"description": "Adjust the theme setting to switch between a dark or light color palette.",
				"paid": true,
				"free": true
			},
			{
				"label": "Mobile App",
				"description": "Download and use a mobile app to log time on-the-go.",
				"paid": true,
				"free": true
			},
			{
				"label": "App Integrations",
				"description": (<>Connect to many other apps with our <RouterLink to="/blog/zapier-integration/">Zapier integration</RouterLink>.</>),
				"paid": true,
				"free": true
			},
			{
				"label": "Invite Team Members",
				"description": "Add members to your team by sending an email invitation.",
				"paid": true,
				"free": false
			},
			{
				"label": "Manage Team Project Permissions",
				"description": "Control which team members can access each project.",
				"paid": true,
				"free": false
			},
			{
				"label": "Manage Team Administrator Roles",
				"description": "Control which team members can access various administration features.",
				"paid": true,
				"free": false
			},
			{
				"label": "Import Time Logs",
				"description": "Load historical (or new) time log data from other systems or data sources by uploading Excel or CSV files.",
				"paid": true,
				"free": false
			}
		]
	}
];
