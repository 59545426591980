import React from 'react'
import PropTypes from 'prop-types'
import AnchorButton from '../shared/AnchorButton'
import cn from 'classnames'
import featureMatrix from '../../featureMatrix'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'

const cardBgDark = "#343a40";

const usePlanCardStyles = makeStyles(theme => {
	const isDark = theme.palette.type === "dark";
	return {
		checkmark: {
			color: theme.palette.primary.main,
		},
		cardDeck: {
			display: "flex",
			justifyContent: "center",
			[theme.breakpoints.down('sm')]: {
				flexFlow: "row wrap",
			},
			"& > *": {
				// Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
				flex: "1 0 0%",
			}
		},
		planCard: {
			display: "flex",
			flexDirection: "column",
			minWidth: "300px",
			maxWidth: "24rem",
			backgroundColor: isDark ? cardBgDark : undefined,
			margin: "0 15px 1rem 15px",
			border: "1px solid " + theme.palette.text.primary,
			"& $planHeader": {
// TODO the LandingV1 CSS is too far reaching. That's why we're having to do this
 				fontSize: theme.typography.pxToRem(22),
				fontWeight: "normal",
				color: isDark ? "inherit" : undefined,
				marginBottom: "0",
			},
		},
		planHeader: {
			// padding: "12px 20px",
			backgroundColor: "rgba(0, 0, 0, 0.03)",
			borderBottomWidth: "1px",
			borderBottomStyle: "solid",
			borderBottomColor: theme.palette.text.primary,
			marginBottom: "0",
		},
		cardBodyTitle: {
			color: theme.palette.text.secondary,
			marginBottom: "0.5rem",
			fontSize: theme.typography.pxToRem(16),
			fontWeight: "bold",
		},
		cardPricing: {
			fontSize: theme.typography.pxToRem(32),
			fontWeight: "500",
			lineHeight: "1.2",
			marginBottom: "1.5rem",
		},
		cardBodyFooter: {
			color: theme.palette.primary.main,
		},
		warning: {
			textAlign: "center",
			color: theme.palette.error.main,
		},
		planCardActions: {
			marginTop: "auto",
			justifyContent: "center",
			paddingBottom: theme.spacing(2.5),
		},
	};
}, {name: "PlanCard"});

export function PlanCards(props) {
	let {webAppUrl, pricePerSeat} = props;

	const classes = usePlanCardStyles();

	return (
		<React.Fragment>
			<div className={classes.cardDeck}>
				<Card elevation={0} className={classes.planCard}>
					<CardHeader
						component="h2"
						disableTypography
						title={(<React.Fragment><FontAwesomeIcon icon="user" fixedWidth/> Free Plan</React.Fragment>)}
						className={classes.planHeader}
					/>
					<CardContent>
						<div className="text-center">
							<div className={classes.cardBodyTitle}>Monthly Price</div>
							<div className={classes.cardPricing}>Free</div>
						</div>
						<p>
							<FontAwesomeIcon icon="check" fixedWidth className={classes.checkmark}/> For solo users
							only
							<br/>
							<FontAwesomeIcon icon="check" fixedWidth className={classes.checkmark}/> Up to 3 active
							projects
						</p>
						<b>Included features:</b>
						<ul>
							<li>Track hours and edit time logs</li>
							<li>Enter and monitor expenses</li>
							<li>Reports</li>
							<li>Mobile app</li>
							<li>CSV export expenses/time logs</li>
						</ul>
					</CardContent>
					<CardActions className={classes.planCardActions}>
						<AnchorButton disabled variant="contained" color="primary" href={webAppUrl + "/app/signup?plan=free"}>
							Sign Up with Free Plan
						</AnchorButton>
					</CardActions>
					<CardContent>
						<div className={classes.warning}>
							New signups are no longer available.
						</div>
					</CardContent>
				</Card>

				<Card elevation={0} className={classes.planCard}>
					<CardHeader
						component="h2"
						disableTypography
						title={(<React.Fragment><FontAwesomeIcon icon="users" fixedWidth/> Team Plan</React.Fragment>)}
						className={classes.planHeader}
					/>
					<CardContent>
						<div className="text-center">
							<div className={classes.cardBodyTitle}>Monthly Price</div>
							<div className={classes.cardPricing}>
								<small>$</small>{pricePerSeat}<small> / person</small>
							</div>
						</div>
						<p>
							<FontAwesomeIcon icon="check" fixedWidth className={classes.checkmark}/> For teams or solo
							users
							<br/>
							<FontAwesomeIcon icon="check" fixedWidth className={classes.checkmark}/> No active project
							limit
						</p>
						<b>Includes all Free Plan features plus:</b>
						<ul>
							<li>Invite team members</li>
							<li>Automatic Invoicing (or manual)</li>
							<li>Import time logs from Excel/CSV</li>
							<li>Excel export expenses/time logs</li>
						</ul>
					</CardContent>
					<CardActions className={classes.planCardActions}>
						<AnchorButton disabled variant="contained" color="primary" href={webAppUrl + "/app/signup?plan=team"}>
							Sign Up with Team Plan
						</AnchorButton>
					</CardActions>
					<CardContent>
						<div className={classes.warning}>
							New signups are no longer available.
						</div>
					</CardContent>
				</Card>
			</div>

			{/*<Grid container className="mt-5">*/}
			{/*	<Grid item xs={12} md={5} className="mb-5">*/}
			{/*		<div className="card border-primary">*/}
			{/*			<h5 className="card-header border-primary">*/}
			{/*				Key Included Features*/}
			{/*			</h5>*/}
			{/*			<ul className="list-group list-group-flush">*/}
			{/*				<li className="list-group-item">Track hours and edit time logs</li>*/}
			{/*				<li className="list-group-item">Enter and monitor expenses</li>*/}
			{/*				<li className="list-group-item">Send invoices to clients</li>*/}
			{/*				<li className="list-group-item">Automatic invoicing</li>*/}
			{/*				<li className="list-group-item">Multiple projects</li>*/}
			{/*				<li className="list-group-item">Reports</li>*/}
			{/*				<li className="list-group-item">Work with team members</li>*/}
			{/*				<li className="list-group-item">Bulk time log import/export</li>*/}
			{/*				<li className="list-group-item">Mobile app &mdash; clock in on the go</li>*/}
			{/*			</ul>*/}
			{/*		</div>*/}
			{/*		<div className="mt-3">*/}
			{/*			&rarr; <RouterLink to="/features">See more Features</RouterLink>*/}
			{/*		</div>*/}
			{/*	</Grid>*/}
			{/*</Grid>*/}

		</React.Fragment>
	);
}
PlanCards.propTypes = {
	webAppUrl: PropTypes.string.isRequired,
	pricePerSeat: PropTypes.number.isRequired,
};

const useFeatureMatrixStyles = makeStyles(theme => {
	const isDark = theme.palette.type === "dark";
	return {
		tableContainer: {
			backgroundColor: isDark ? cardBgDark : undefined,
			maxWidth: "1000px",
			marginBottom: "1rem",
			border: isDark ? "1px solid rgb(100,100,100)" : "1px solid rgb(224,224,224)",
		},
		table: {
			"& td, th": {
				...theme.typography.body2,
				verticalAlign: "top",
			}
		},
		tableHead: {
			backgroundColor: isDark ? undefined : "#e9ecef",
			"& th": {
				...theme.typography.h6,
				color: isDark ? theme.palette.primary.main : "#495057",
				verticalAlign: "bottom",
			},
		},
		featureCell: {
			[theme.breakpoints.up('md')]: {
				maxWidth: "540px",
				padding: "12px 65px 12px 12px",
			},
		},
		featureLabel: {
			...theme.typography.h6,
			paddingBottom: "0.2rem",
		},
		featureDescription: {
			color: theme.palette.text.secondary,
		},
	};
}, {name: "FeatureMatrix"});

export function PlanFeatureMatrix(props) {
	const classes = useFeatureMatrixStyles();
	return (
		<div className="tt-screen-content">
			<div className="d-flex justify-content-center mt-4">

				<TableContainer component={Paper} elevation={2} className={classes.tableContainer}>
					<Table className={classes.table} aria-label="Feature List">
						{featureMatrix.map((matrixSection, sectionNum) => (
							<React.Fragment key={sectionNum}>
								<TableHead className={classes.tableHead}>
									<TableRow>
										<TableCell>{matrixSection.title}</TableCell>
										<TableCell align="center">Free Plan</TableCell>
										<TableCell align="center">Team Plan</TableCell>
									</TableRow>
								</TableHead>
								{matrixSection.features && matrixSection.features.length > 0 && (
									<TableBody>
										{matrixSection.features.map((feature, featureNum) => (
											<TableRow key={featureNum}>
												<TableCell component="th" scope="row" className={classes.featureCell}>
													<div className={classes.featureLabel}>{feature.label}</div>
													{feature.description && (
														<div className={classes.featureDescription}>{feature.description}</div>
													)}
												</TableCell>
												<TableCell align="center"><FeatureBool value={feature.free}/></TableCell>
												<TableCell align="center"><FeatureBool value={feature.paid} bonus/></TableCell>
											</TableRow>
										))}
									</TableBody>
								)}
							</React.Fragment>
						))}
					</Table>
				</TableContainer>
			</div>
		</div>
	);
}
PlanFeatureMatrix.propTypes = {

};

export function PlanBulletPoints(props) {
	return (
		<ul>
			<li>Limit one free trial per team and one trial per user.</li>
			<li>The Free Plan is for solo users only.  Limit one Free Plan per user.</li>
			<li>Billing is on a monthly basis.  Cancel your subscription at any time to stop further billing.</li>
			<li>All prices are USD.  Tax will be added where applicable.</li>
		</ul>
	);
}
PlanBulletPoints.propTypes = {

};

const useFeatureBoolStyles = makeStyles(theme => {
	return {
		affirmativeColor: {
			color: theme.palette.primary.main,
		},
		nayColor: {
			color: theme.palette.text.secondary,
		},
	};
}, {name: "FeatureBool"});

function FeatureBool(props) {
	const {value, bonus} = props;
	const classes = useFeatureBoolStyles();
	if (!!value) {
		return value === true ? (
			<span className={classes.affirmativeColor}><Yes/></span>
		) : bonus ? (
			<strong className={classes.affirmativeColor}>{value}</strong>
		) : value;
	} else {
		return (<span className={classes.nayColor}><No/></span>);
	}
}
FeatureBool.propTypes = {
	value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
	bonus: PropTypes.bool,
}

function Yes(props) {
	return (<FontAwesomeIcon icon="check" size="lg"/>);
}

function No(props) {
	return (<span>&mdash;</span>);
}
