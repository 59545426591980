import React from 'react'
import { useSiteMetadata } from '../components/SiteData'
import AppLayout from '../components/AppLayout'
import FeatureContentPage from '../components/FeatureContentPage'
import PageInfo from '../components/PageInfo'
import Brand from '../components/shared/Brand'
import { PlanBulletPoints, PlanCards, PlanFeatureMatrix } from '../components/common/PlanInfo'
import AnchorButton from '../components/shared/AnchorButton'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const usePricingStyles = makeStyles(theme => ({
	planComparisonContainer: {
		backgroundColor: "#fff",
	},
}), {name: "Pricing"});

export default function Pricing(props) {
	const {productName, webAppUrl, pricePerSeat} = useSiteMetadata();

	const classes = usePricingStyles();

	return (
		<AppLayout>
			<FeatureContentPage
				title="Pricing"
				description={(
					<p>
						<Brand/> pricing is simple and scalable
						because you only pay for the number of people that will use the system.
						A free plan is also available.
					</p>
				)}
			>
				<PageInfo
					title={"Pricing - " + productName}
					description={productName + " is a time and expense management system with pricing that is simple and scalable. You only pay for the number of people that will use the system."}
				/>

				<div className="container-fluid px-sm-5 pb-5">
					<PlanCards
						webAppUrl={webAppUrl}
						pricePerSeat={pricePerSeat}
					/>
				</div>

				<div className={classes.planComparisonContainer}>
					<div className="container-fluid px-sm-5 py-5">
						<Typography variant="h1" component="h2">Compare Plans</Typography>

						<p>
							Both plans have plenty of features. Pick the one that matches your needs.
						</p>

						<PlanFeatureMatrix/>

					</div>
				</div>

				<div className="container-fluid px-sm-5 pt-5 pb-2">
					<PlanBulletPoints/>
				</div>

			</FeatureContentPage>
		</AppLayout>
	);
}
